import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

const Locations = () => (
  <Layout>
    <h1>Privacy</h1>

    <p>Our mobile app will store your learning journal as you save it in a database, hosted on the 'cloud'.</p>

    <p>We will use this data to provide our service to you, this will include allowing you to view past entries in your
      learning journal, and optionally allowing you to share data on social media, or via other means.</p>

    <p>We will not use this data for any other purpose, unless required to do so by law.</p>

    <p>Additionally, we may collect anonymous data about your usage, and any errors that occur while using the app.</p>

    <p>If you wish to exercise your right to be forgotten, or make a Subject Access Request, please do so using our</p>
    <p>contact functionality within the app.</p>
  </Layout>
);

export default Locations;
